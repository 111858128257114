/* eslint-disable */
if (!localStorage.getItem('search-and-bookmark-data')) {
  localStorage.setItem('search-and-bookmark-data', JSON.stringify({
    pages: {
      key: 'title',
      data: [
                {
          title: 'Main',
                    route: 'main',
                              icon: 'HomeIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Финансы',
                    route: 'finance',
                              icon: 'CreditCardIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Магазин',
                    route: 'shop',
                              icon: 'ShoppingBagIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Мои покупки',
                    route: 'orders',
                              icon: 'ShoppingCartIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Личные приглашения',
                    route: '',
                              icon: 'CornerDownRightIcon',
                            },
                {
          title: 'Структура - Дерево',
                              icon: 'CommandIcon',
                            },
                {
          title: 'Работа с сетью',
                              icon: 'Share2Icon',
                            },
                {
          title: 'Новости',
                    route: 'news',
                              icon: 'TabletIcon',
                              isBookmarked: '',
                  },
                {
          title: 'Информация',
                              icon: 'FileTextIcon',
                            },
                {
          title: 'Профиль',
                    route: 'profile',
                              icon: 'UserIcon',
                              isBookmarked: '',
                  },
              ]
    },
  }))
}
// prettier-ignore
export default [
    {
    title: 'Main',
        route: 'main',
            icon: 'HomeIcon',
          },
    {
    title: 'Финансы',
        route: 'finance',
            icon: 'CreditCardIcon',
          },
    {
    title: 'Магазин',
        route: 'shop',
            icon: 'ShoppingBagIcon',
          },
    {
    title: 'Мои покупки',
        route: 'orders',
            icon: 'ShoppingCartIcon',
          },
    {
    title: 'Личные приглашения',
        route: '',
            icon: 'CornerDownRightIcon',
            children: [{"title":"\u0421\u043f\u0438\u0441\u043e\u043a","route":"personal-invitations","icon":"CircleIcon","isBookmarked":false},{"title":"\u041f\u0440\u0438\u0433\u043b\u0430\u0441\u0438\u0442\u044c \u043d\u043e\u0432\u0438\u0447\u043a\u0430","route":"invite","icon":"CircleIcon","isBookmarked":false}],
      },
    {
    title: 'Структура - Дерево',
            icon: 'CommandIcon',
            children: [{"title":"\u041b\u0438\u043d\u0435\u0439\u043d\u0430\u044f","route":"grid-structure","icon":"CircleIcon","isBookmarked":false},{"title":"\u0411\u0438\u043d\u0430\u0440\u043d\u043e-\u0443\u0440\u043e\u0432\u043d\u0435\u0432\u0430\u044f","route":"tree-structure","isBookmarked":false}],
      },
    {
    title: 'Работа с сетью',
            icon: 'Share2Icon',
            children: [{"title":"\u041d\u043e\u0432\u0438\u0447\u043a\u0438","route":"newcomers","icon":"CircleIcon","isBookmarked":false},{"title":"\u0414\u043d\u0438 \u0440\u043e\u0436\u0434\u0435\u043d\u0438\u044f","route":"birthdays","isBookmarked":false}],
      },
    {
    title: 'Новости',
        route: 'news',
            icon: 'TabletIcon',
          },
    {
    title: 'Информация',
            icon: 'FileTextIcon',
            children: [{"title":"\u041a\u043e\u043d\u0442\u0430\u043a\u0442\u044b","route":"about","icon":"CircleIcon","isBookmarked":false},{"title":"\u0414\u043e\u043a\u0443\u043c\u0435\u043d\u0442\u044b","route":"docs","icon":"CircleIcon","isBookmarked":false},{"title":"\u041f\u0440\u0430\u0432\u0438\u043b\u0430 \u043f\u043e\u043a\u0443\u043f\u043a\u0438 ","route":"how-to-by","icon":"CircleIcon","isBookmarked":false}],
      },
    {
    title: 'Профиль',
        route: 'profile',
            icon: 'UserIcon',
          },
  ]
